/* This file was generated by Supernova and should not be changed manually */
:root {
  --color-base-colors-black-100: #0000000d;
  --color-base-colors-black-200: #0000001a;
  --color-base-colors-black-300: #00000033;
  --color-base-colors-black-400: #0000004d;
  --color-base-colors-black-500: #00000080;
  --color-base-colors-black-600: #000000cc;
  --color-base-colors-black-700: #000000d9;
  --color-base-colors-black-800: #000000e5;
  --color-base-colors-black-900: #000000f2;
  --color-base-colors-black-1000: #000000;
  --color-base-colors-white-100: #ffffff0d;
  --color-base-colors-white-200: #ffffff1a;
  --color-base-colors-white-300: #ffffff33;
  --color-base-colors-white-400: #ffffff4d;
  --color-base-colors-white-500: #ffffff80;
  --color-base-colors-white-600: #ffffffcc;
  --color-base-colors-white-700: #ffffffd9;
  --color-base-colors-white-800: #ffffffe5;
  --color-base-colors-white-900: #fffffff2;
  --color-base-colors-grey-50: #fcfcfd;
  --color-base-colors-white-1000: #ffffff;
  --color-base-colors-grey-100: #f8fafc;
  --color-base-colors-grey-200: #eef2f6;
  --color-base-colors-grey-300: #e3e8ef;
  --color-base-colors-grey-400: #cdd5df;
  /* base color */
  --color-base-colors-grey-500: #9aa4b2;
  --color-base-colors-grey-600: #697586;
  --color-base-colors-grey-700: #4b5565;
  --color-base-colors-grey-800: #364152;
  --color-base-colors-grey-900: #202939;
  --color-base-colors-grey-1000: #121926;
  --color-base-colors-blue-50: #f5f8ff;
  --color-base-colors-blue-100: #eff4ff;
  --color-base-colors-blue-200: #d1e0ff;
  --color-base-colors-blue-300: #b2ccff;
  --color-base-colors-blue-400: #84adff;
  --color-base-colors-blue-500: #528bff;
  /* Brand color */
  --color-base-colors-blue-600: #2970ff;
  --color-base-colors-blue-700: #155eef;
  --color-base-colors-blue-800: #004eeb;
  --color-base-colors-blue-900: #0040c1;
  --color-base-colors-blue-1000: #00359e;
  --color-base-colors-bluelight-50: #f4f9ff;
  --color-base-colors-bluelight-100: #eff8ff;
  --color-base-colors-bluelight-200: #d1e9ff;
  --color-base-colors-bluelight-300: #b2ddff;
  --color-base-colors-bluelight-400: #84caff;
  /* base color */
  --color-base-colors-bluelight-500: #53b0fd;
  --color-base-colors-bluelight-600: #2e90fa;
  --color-base-colors-bluelight-700: #156fee;
  --color-base-colors-bluelight-800: #175cd3;
  --color-base-colors-bluelight-900: #1849a9;
  --color-base-colors-bluelight-1000: #194084;
  --color-base-colors-purple-50: #faf8fc;
  --color-base-colors-purple-100: #eae0f1;
  --color-base-colors-purple-200: #cab3dd;
  --color-base-colors-purple-300: #a780c6;
  --color-base-colors-purple-400: #844daf;
  --color-base-colors-purple-500: #69269d;
  --color-base-colors-purple-600: #4f038c;
  --color-base-colors-purple-700: #480384;
  --color-base-colors-purple-800: #3f0279;
  --color-base-colors-purple-900: #36026f;
  --color-base-colors-purple-1000: #26015c;
  --color-base-colors-red-50: #fffbfa;
  --color-base-colors-red-100: #fef3f2;
  --color-base-colors-red-200: #fee4e2;
  --color-base-colors-red-300: #fecdca;
  --color-base-colors-red-400: #fca19b;
  /* base color */
  --color-base-colors-red-500: #f97066;
  --color-base-colors-red-600: #f04437;
  --color-base-colors-red-700: #d92c20;
  --color-base-colors-red-800: #b42318;
  --color-base-colors-red-900: #912018;
  --color-base-colors-red-1000: #7a2619;
  --color-base-colors-green-50: #f6fef9;
  --color-base-colors-green-100: #ecfdf3;
  --color-base-colors-green-200: #d1fadf;
  --color-base-colors-green-300: #a6f4c5;
  --color-base-colors-green-400: #6be9a6;
  /* base color */
  --color-base-colors-green-500: #31d583;
  /* base color */
  --color-base-colors-green-600: #12b669;
  --color-base-colors-green-700: #039754;
  --color-base-colors-green-800: #027a48;
  --color-base-colors-green-900: #055f3a;
  --color-base-colors-green-1000: #054e31;
  --color-base-colors-orange-50: #fefaf5;
  --color-base-colors-orange-100: #fef6ee;
  --color-base-colors-orange-200: #fcead7;
  --color-base-colors-orange-300: #f8dbaf;
  --color-base-colors-orange-400: #f7b279;
  /* base color */
  --color-base-colors-orange-500: #f38743;
  /* base color */
  --color-base-colors-orange-600: #ef681f;
  --color-base-colors-orange-700: #df4f16;
  --color-base-colors-orange-800: #b93814;
  --color-base-colors-orange-900: #932f18;
  --color-base-colors-orange-1000: #772917;
  --color-base-colors-pink-50: #fef6fb;
  --color-base-colors-pink-100: #fdf2fa;
  --color-base-colors-pink-200: #fce7f6;
  --color-base-colors-pink-300: #fcceee;
  --color-base-colors-pink-400: #f9a7df;
  /* base color */
  --color-base-colors-pink-500: #f570c7;
  --color-base-colors-pink-600: #ee46bc;
  --color-base-colors-pink-700: #dc2590;
  --color-base-colors-pink-800: #c01573;
  --color-base-colors-pink-900: #9e155e;
  --color-base-colors-pink-1000: #851651;
  --color-base-colors-rose-50: #fff5f6;
  --color-base-colors-rose-100: #fff1f3;
  --color-base-colors-rose-200: #ffe4e8;
  --color-base-colors-rose-300: #fecdd6;
  --color-base-colors-rose-400: #fea2b3;
  /* base color */
  --color-base-colors-rose-500: #fc6f8d;
  --color-base-colors-rose-600: #f53d68;
  --color-base-colors-rose-700: #e31a53;
  --color-base-colors-rose-800: #c00f47;
  --color-base-colors-rose-900: #a10f42;
  --color-base-colors-rose-1000: #89113d;
  --color-base-colors-yellow-50: #fff7e9;
  --color-base-colors-yellow-100: #fee8bd;
  --color-base-colors-yellow-200: #fdd890;
  --color-base-colors-yellow-300: #fdc964;
  --color-base-colors-yellow-400: #fcc14e;
  /* base color */
  --color-base-colors-yellow-500: #fcc04c;
  --color-base-colors-yellow-600: #fcb222;
  --color-base-colors-yellow-700: #d7991f;
  --color-base-colors-yellow-800: #a17419;
  --color-base-colors-yellow-900: #65470d;
  --color-base-colors-yellow-1000: #4c350a;
  --color-base-colors-fuchsia-50: #fdf4ff;
  --color-base-colors-fuchsia-100: #f9e8ff;
  --color-base-colors-fuchsia-200: #f3cfff;
  --color-base-colors-fuchsia-300: #eeaafd;
  --color-base-colors-fuchsia-400: #e477fb;
  /* base color */
  --color-base-colors-fuchsia-500: #d444f1;
  --color-base-colors-fuchsia-600: #bb24d5;
  --color-base-colors-fuchsia-700: #9d1ab1;
  --color-base-colors-fuchsia-800: #831890;
  --color-base-colors-fuchsia-900: #6e1976;
  --color-base-colors-fuchsia-1000: #48034f;
  --color-base-colors-cyan-50: #f5feff;
  --color-base-colors-cyan-100: #ecfdff;
  --color-base-colors-cyan-200: #cff9fe;
  --color-base-colors-cyan-300: #a5f0fb;
  --color-base-colors-cyan-400: #67e3f9;
  /* base color */
  --color-base-colors-cyan-500: #22cbed;
  --color-base-colors-cyan-600: #05aed4;
  --color-base-colors-cyan-700: #078ab2;
  --color-base-colors-cyan-800: #0d6f90;
  --color-base-colors-cyan-900: #155b75;
  --color-base-colors-cyan-1000: #154b63;
  --color-base-colors-teal-50: #f6fefc;
  --color-base-colors-teal-100: #f0fdf9;
  --color-base-colors-teal-200: #ccfbef;
  --color-base-colors-teal-300: #99f6e0;
  --color-base-colors-teal-400: #5ee9cf;
  /* base color */
  --color-base-colors-teal-500: #2ed2b7;
  --color-base-colors-teal-600: #15b79e;
  --color-base-colors-teal-700: #0e9384;
  --color-base-colors-teal-800: #0f7569;
  --color-base-colors-teal-900: #125d56;
  --color-base-colors-teal-1000: #134e48;
  --color-base-colors-greenlight-50: #fafdf7;
  --color-base-colors-greenlight-100: #f5fbee;
  --color-base-colors-greenlight-200: #e6f4d7;
  --color-base-colors-greenlight-300: #cdeaaf;
  --color-base-colors-greenlight-400: #acdc79;
  /* base color */
  --color-base-colors-greenlight-500: #86cb3c;
  --color-base-colors-greenlight-600: #669f2a;
  --color-base-colors-greenlight-700: #4f7a21;
  --color-base-colors-greenlight-800: #3f611a;
  --color-base-colors-greenlight-900: #335015;
  --color-base-colors-greenlight-1000: #2b4212;
  --color-color-bg-blanket: #000a1eb2;
  --color-base-colors-violet-50: #fbfaff;
  --color-base-colors-violet-100: #f5f3ff;
  --color-base-colors-violet-200: #ece9fe;
  --color-base-colors-violet-300: #ddd6fe;
  --color-base-colors-violet-400: #c3b5fd;
  --color-base-colors-violet-500: #a48afa;
  --color-base-colors-violet-600: #875af7;
  --color-base-colors-violet-700: #7839ed;
  --color-base-colors-violet-800: #6927da;
  --color-base-colors-violet-900: #5720b7;
  --color-base-colors-violet-1000: #491c96;
  /* default page background */
  --color-color-bg-base-default: var(--color-base-colors-white-1000);
  /* hight contrast background color */
  --color-color-bg-default-inverse: var(--color-base-colors-grey-1000);
  --color-color-bg-accent-red-lightest-default: var(--color-base-colors-red-50);
  --color-color-bg-accent-red-lightest-hovered: var(
    --color-base-colors-red-100
  );
  --color-color-bg-accent-red-lightest-pressed: var(
    --color-base-colors-red-200
  );
  --color-color-bg-brand-lightest-default: var(--color-base-colors-blue-50);
  --color-color-bg-brand-lightest-pressed: var(--color-base-colors-blue-200);
  --color-color-bg-brand-light-default: var(--color-base-colors-blue-200);
  --color-color-bg-brand-light-hovered: var(--color-base-colors-blue-400);
  --color-color-bg-brand-lightest-hovered: var(--color-base-colors-blue-100);
  --color-color-bg-brand-light-pressed: var(--color-base-colors-blue-300);
  --color-color-bg-danger-lighter-default: var(--color-base-colors-red-100);
  --color-color-bg-danger-lighter-hovered: var(--color-base-colors-red-200);
  --color-color-bg-danger-bold-default: var(--color-base-colors-red-600);
  --color-color-bg-danger-bold-hovered: var(--color-base-colors-red-700);
  --color-color-bg-danger-lighter-pressed: var(--color-base-colors-red-300);
  --color-color-bg-danger-bold-pressed: var(--color-base-colors-red-800);
  --color-color-bg-brand-bold-default: var(--color-base-colors-blue-600);
  /* Use for links in default or hovered state. Add underline for hovered states. */
  --color-color-link-default: var(--color-color-bg-brand-bold-default);
  --color-color-bg-brand-bold-pressed: var(--color-base-colors-blue-800);
  /* Use for links in a pressed state */
  --color-color-link-pressed: var(--color-color-bg-brand-bold-pressed);
  /* Use for links in a visited state */
  --color-color-link-visited: var(--color-base-colors-purple-800);
  --color-color-border-bolder: var(--color-base-colors-grey-700);
  --color-color-border-bold: var(--color-base-colors-grey-600);
  --color-color-border-light: var(--color-base-colors-grey-500);
  --color-color-border-lighter: var(--color-base-colors-grey-400);
  --color-color-border-lightest: var(--color-base-colors-grey-300);
  --color-color-bg-warning-lighter-default: var(--color-base-colors-yellow-100);
  --color-color-bg-warning-bold-default: var(--color-base-colors-yellow-600);
  --color-color-bg-warning-lighter-hovered: var(--color-base-colors-yellow-200);
  --color-color-bg-warning-bold-hovered: var(--color-base-colors-yellow-700);
  --color-color-bg-warning-lighter-pressed: var(--color-base-colors-yellow-300);
  --color-color-bg-warning-bold-pressed: var(--color-base-colors-yellow-800);
  --color-color-bg-success-lighter-default: var(--color-base-colors-green-100);
  --color-color-bg-success-bold-default: var(--color-base-colors-green-600);
  --color-color-bg-success-lighter-hovered: var(--color-base-colors-green-200);
  --color-color-bg-success-lighter-pressed: var(--color-base-colors-green-300);
  --color-color-bg-success-bold-hovered: var(--color-base-colors-green-700);
  --color-color-bg-success-bold-pressed: var(--color-base-colors-green-800);
  --color-color-bg-info-lighter-default: var(--color-base-colors-bluelight-100);
  --color-color-bg-info-lighter-hovered: var(--color-base-colors-bluelight-200);
  --color-color-bg-info-bold-default: var(--color-base-colors-bluelight-600);
  --color-color-bg-info-lighter-pressed: var(--color-base-colors-bluelight-300);
  --color-color-bg-info-bold-hovered: var(--color-base-colors-bluelight-700);
  --color-color-bg-info-bold-pressed: var(--color-base-colors-bluelight-800);
  --color-color-bg-brand-bold-hovered: var(--color-base-colors-blue-700);
  --color-color-bg-accent-red-lighter-default: var(--color-base-colors-red-100);
  --color-color-bg-accent-red-lighter-hovered: var(--color-base-colors-red-200);
  --color-color-bg-accent-red-lighter-pressed: var(--color-base-colors-red-300);
  --color-color-bg-accent-red-bolder-default: var(--color-base-colors-red-800);
  --color-color-bg-accent-red-bolder-hovered: var(--color-base-colors-red-900);
  --color-color-bg-accent-red-bolder-pressed: var(--color-base-colors-red-1000);
  --color-color-bg-neutral-lightest-default: var(--color-base-colors-grey-100);
  --color-color-bg-neutral-lightest-hovered: var(--color-base-colors-grey-200);
  --color-color-bg-neutral-lightest-pressed: var(--color-base-colors-grey-300);
  --color-color-bg-neutral-lighter-default: var(--color-base-colors-grey-200);
  --color-color-bg-neutral-lighter-hovered: var(--color-base-colors-grey-300);
  --color-color-bg-neutral-lighter-pressed: var(--color-base-colors-grey-400);
  --color-color-bg-neutral-light-default: var(--color-base-colors-grey-300);
  --color-color-bg-neutral-light-hovered: var(--color-base-colors-grey-400);
  --color-color-bg-neutral-light-pressed: var(--color-base-colors-grey-500);
  --color-color-bg-neutral-bold-default: var(--color-base-colors-grey-500);
  --color-color-bg-neutral-bold-hovered: var(--color-base-colors-grey-600);
  --color-color-bg-neutral-bold-pressed: var(--color-base-colors-grey-700);
  --color-color-border-support-danger-lighter: var(--color-base-colors-red-200);
  --color-color-border-support-warning-lighter: var(
    --color-base-colors-orange-200
  );
  --color-color-border-support-success-lighter: var(
    --color-base-colors-green-200
  );
  --color-color-border-support-info-lighter: var(
    --color-base-colors-bluelight-200
  );
  --color-color-border-support-info-bold: var(
    --color-base-colors-bluelight-700
  );
  --color-color-border-support-danger-bold: var(--color-base-colors-red-700);
  --color-color-border-support-warning-bold: var(
    --color-base-colors-yellow-700
  );
  --color-color-border-support-success-bold: var(--color-base-colors-green-700);
  --color-color-bg-accent-green-lightest-default: var(
    --color-base-colors-green-50
  );
  --color-color-bg-accent-green-lightest-hovered: var(
    --color-base-colors-green-100
  );
  --color-color-bg-accent-green-lightest-pressed: var(
    --color-base-colors-green-200
  );
  --color-color-bg-accent-green-lighter-default: var(
    --color-base-colors-green-100
  );
  --color-color-bg-accent-green-lighter-hovered: var(
    --color-base-colors-green-200
  );
  --color-color-bg-accent-green-lighter-pressed: var(
    --color-base-colors-green-300
  );
  --color-color-bg-accent-green-bolder-default: var(
    --color-base-colors-green-800
  );
  --color-color-bg-accent-green-bolder-hovered: var(
    --color-base-colors-green-900
  );
  --color-color-bg-accent-green-bolder-pressed: var(
    --color-base-colors-green-1000
  );
  --color-color-bg-accent-orange-lightest-default: var(
    --color-base-colors-orange-50
  );
  --color-color-bg-accent-orange-lightest-hovered: var(
    --color-base-colors-orange-100
  );
  --color-color-bg-accent-orange-lightest-pressed: var(
    --color-base-colors-orange-200
  );
  --color-color-bg-accent-orange-lighter-default: var(
    --color-base-colors-orange-100
  );
  --color-color-bg-accent-orange-lighter-hovered: var(
    --color-base-colors-orange-200
  );
  --color-color-bg-accent-orange-lighter-pressed: var(
    --color-base-colors-orange-300
  );
  --color-color-bg-accent-orange-bolder-default: var(
    --color-base-colors-orange-800
  );
  --color-color-bg-accent-orange-bolder-hovered: var(
    --color-base-colors-orange-900
  );
  --color-color-bg-accent-orange-bolder-pressed: var(
    --color-base-colors-orange-1000
  );
  --color-color-bg-brand-lighter-default: var(--color-base-colors-blue-100);
  --color-color-bg-brand-lighter-pressed: var(--color-base-colors-blue-300);
  --color-color-bg-brand-lighter-hovered: var(--color-base-colors-blue-200);
  /* Use for primary text, such as body copy, sentence case headers;  Use as hover text color for $text-light */
  --color-color-fg-default: var(--color-base-colors-grey-900);
  /* Use for secondary text, such as navigation, light button links, Inputs labels color; Use as hover text color for $text-lighter */
  --color-color-fg-bold: var(--color-base-colors-grey-800);
  /* Use for tertiary text, such as helper text and meta-data; Use as hover text color for $text-lightest */
  --color-color-fg-light: var(--color-base-colors-grey-700);
  /* Use for text on interactive and bold backgrounds. */
  --color-color-fg-inverse: var(--color-base-colors-white-1000);
  /* Use for text on interactive and bold backgrounds. */
  --color-color-fg-inverse-lighter: var(--color-base-colors-white-600);
  /* Use for text on interactive and bold backgrounds. */
  --color-color-fg-inverse-lightest: var(--color-base-colors-white-400);
  /* Use for critical text, such as fields error messaging. */
  --color-color-fg-danger-default: var(--color-base-colors-red-600);
  /* Use for critical text, such as fields error messaging. */
  --color-color-fg-danger-bold: var(--color-base-colors-red-900);
  /* Use for text to emphasize caution. */
  --color-color-fg-warning-default: var(--color-base-colors-yellow-600);
  /* Use for text to emphasize caution. */
  --color-color-fg-warning-bold: var(--color-base-colors-yellow-900);
  /* Use for text to communicate successful results. */
  --color-color-fg-success-default: var(--color-base-colors-green-600);
  /* Use for text to communicate successful results. */
  --color-color-fg-success-bold: var(--color-base-colors-green-900);
  /* Use for informative text to communicate something is in progress. */
  --color-color-fg-info-default: var(--color-base-colors-bluelight-600);
  /* Use for informative text to communicate something is in progress. */
  --color-color-fg-info-bold: var(--color-base-colors-bluelight-900);
  /* Use for tertiary text, such as helper text and meta-data; */
  --color-color-fg-lighter: var(--color-base-colors-grey-600);
  /* Use for text in selected or opened states. */
  --color-color-fg-selected: var(--color-base-colors-blue-800);
  /* Use for disabled text state. */
  --color-color-fg-disabled: var(--color-base-colors-black-500);
  /* Use for text that reinforces our brand. */
  --color-color-fg-brand-default: var(--color-base-colors-blue-700);
  /* Use for text that reinforces our brand. */
  --color-color-fg-brand-bold: var(--color-base-colors-blue-900);
  /* Use for text on interactive and bold backgrounds. */
  --color-color-fg-inverse-light: var(--color-base-colors-white-800);
  --color-color-fg-accent-red-default: var(--color-base-colors-red-600);
  --color-color-fg-accent-red-bold: var(--color-base-colors-red-700);
  --color-color-fg-accent-green-default: var(--color-base-colors-green-600);
  --color-color-fg-accent-green-bold: var(--color-base-colors-green-700);
  --color-color-fg-accent-orange-default: var(--color-base-colors-orange-600);
  --color-color-fg-accent-orange-bold: var(--color-base-colors-orange-700);
  --color-color-fg-accent-pink-default: var(--color-base-colors-pink-600);
  --color-color-fg-accent-pink-bold: var(--color-base-colors-pink-700);
  --color-color-fg-accent-rose-default: var(--color-base-colors-rose-600);
  --color-color-fg-accent-rose-bold: var(--color-base-colors-rose-700);
  --color-color-fg-accent-yellow-default: var(--color-base-colors-yellow-600);
  --color-color-fg-accent-yellow-bold: var(--color-base-colors-yellow-700);
  --color-color-fg-accent-cyan-default: var(--color-base-colors-cyan-600);
  --color-color-fg-accent-cyan-bold: var(--color-base-colors-cyan-700);
  --color-color-fg-accent-teal-default: var(--color-base-colors-teal-600);
  --color-color-fg-accent-teal-bold: var(--color-base-colors-teal-700);
  --color-color-fg-accent-green-light-default: var(
    --color-base-colors-greenlight-600
  );
  --color-color-fg-accent-green-light-bold: var(
    --color-base-colors-greenlight-700
  );
  /* Use for tertiary text, such as helper text and meta-data; */
  --color-color-fg-lightest: var(--color-base-colors-grey-500);
  --color-color-bg-danger-lightest-default: var(--color-base-colors-red-50);
  --color-color-bg-danger-lightest-hovered: var(--color-base-colors-red-100);
  --color-color-bg-danger-lightest-pressed: var(--color-base-colors-red-200);
  --color-color-bg-warning-lightest-default: var(--color-base-colors-yellow-50);
  --color-color-bg-warning-lightest-hovered: var(
    --color-base-colors-yellow-100
  );
  --color-color-bg-warning-lightest-pressed: var(
    --color-base-colors-yellow-200
  );
  --color-color-bg-success-lightest-default: var(--color-base-colors-green-50);
  --color-color-bg-success-lightest-hovered: var(--color-base-colors-green-100);
  --color-color-bg-success-lightest-pressed: var(--color-base-colors-green-200);
  --color-color-bg-info-lightest-default: var(--color-base-colors-bluelight-50);
  --color-color-bg-info-lightest-hovered: var(
    --color-base-colors-bluelight-100
  );
  --color-color-bg-info-lightest-pressed: var(
    --color-base-colors-bluelight-200
  );
  --color-color-bg-brand-bolder-default: var(--color-base-colors-blue-800);
  --color-color-bg-brand-bolder-hovered: var(--color-base-colors-blue-900);
  --color-color-bg-brand-bolder-pressed: var(--color-base-colors-blue-1000);
  --color-color-bg-accent-pink-lightest-default: var(
    --color-base-colors-pink-50
  );
  --color-color-bg-accent-pink-lightest-hovered: var(
    --color-base-colors-pink-100
  );
  --color-color-bg-accent-pink-lightest-pressed: var(
    --color-base-colors-pink-200
  );
  --color-color-bg-accent-pink-lighter-default: var(
    --color-base-colors-pink-100
  );
  --color-color-bg-accent-pink-lighter-hovered: var(
    --color-base-colors-pink-200
  );
  --color-color-bg-accent-pink-lighter-pressed: var(
    --color-base-colors-pink-300
  );
  --color-color-bg-accent-pink-bolder-default: var(
    --color-base-colors-pink-800
  );
  --color-color-bg-accent-pink-bolder-hovered: var(
    --color-base-colors-pink-900
  );
  --color-color-bg-accent-pink-bolder-pressed: var(
    --color-base-colors-pink-1000
  );
  --color-color-bg-accent-rose-lightest-default: var(
    --color-base-colors-rose-50
  );
  --color-color-bg-accent-rose-lightest-hovered: var(
    --color-base-colors-rose-100
  );
  --color-color-bg-accent-rose-lightest-pressed: var(
    --color-base-colors-rose-200
  );
  --color-color-bg-accent-rose-lighter-default: var(
    --color-base-colors-rose-100
  );
  --color-color-bg-accent-rose-lighter-hovered: var(
    --color-base-colors-rose-200
  );
  --color-color-bg-accent-rose-lighter-pressed: var(
    --color-base-colors-rose-300
  );
  --color-color-bg-accent-rose-bolder-default: var(
    --color-base-colors-rose-800
  );
  --color-color-bg-accent-rose-bolder-hovered: var(
    --color-base-colors-rose-900
  );
  --color-color-bg-accent-rose-bolder-pressed: var(
    --color-base-colors-rose-1000
  );
  --color-color-bg-accent-yellow-lightest-default: var(
    --color-base-colors-yellow-50
  );
  --color-color-bg-accent-yellow-lightest-hovered: var(
    --color-base-colors-yellow-100
  );
  --color-color-bg-accent-yellow-lightest-pressed: var(
    --color-base-colors-yellow-200
  );
  --color-color-bg-accent-yellow-lighter-default: var(
    --color-base-colors-yellow-100
  );
  --color-color-bg-accent-yellow-lighter-hovered: var(
    --color-base-colors-yellow-200
  );
  --color-color-bg-accent-yellow-lighter-pressed: var(
    --color-base-colors-yellow-300
  );
  --color-color-bg-accent-yellow-bolder-default: var(
    --color-base-colors-yellow-800
  );
  --color-color-bg-accent-yellow-bolder-hovered: var(
    --color-base-colors-yellow-900
  );
  --color-color-bg-accent-yellow-bolder-pressed: var(
    --color-base-colors-yellow-1000
  );
  --color-color-bg-accent-cyan-lightest-default: var(
    --color-base-colors-cyan-50
  );
  --color-color-bg-accent-cyan-lightest-hovered: var(
    --color-base-colors-cyan-100
  );
  --color-color-bg-accent-cyan-lightest-pressed: var(
    --color-base-colors-cyan-200
  );
  --color-color-bg-accent-cyan-lighter-default: var(
    --color-base-colors-cyan-100
  );
  --color-color-bg-accent-cyan-lighter-hovered: var(
    --color-base-colors-cyan-200
  );
  --color-color-bg-accent-cyan-lighter-pressed: var(
    --color-base-colors-cyan-300
  );
  --color-color-bg-accent-cyan-bolder-default: var(
    --color-base-colors-cyan-800
  );
  --color-color-bg-accent-cyan-bolder-hovered: var(
    --color-base-colors-cyan-900
  );
  --color-color-bg-accent-cyan-bolder-pressed: var(
    --color-base-colors-cyan-1000
  );
  --color-color-bg-accent-violet-lightest-default: var(
    --color-base-colors-violet-50
  );
  --color-color-bg-accent-violet-lightest-hovered: var(
    --color-base-colors-violet-100
  );
  --color-color-bg-accent-violet-lightest-pressed: var(
    --color-base-colors-violet-200
  );
  --color-color-bg-accent-violet-lighter-default: var(
    --color-base-colors-violet-100
  );
  --color-color-bg-accent-violet-lighter-hovered: var(
    --color-base-colors-violet-200
  );
  --color-color-bg-accent-violet-lighter-pressed: var(
    --color-base-colors-violet-300
  );
  --color-color-bg-accent-violet-bold-default: var(
    --color-base-colors-violet-600
  );
  --color-color-bg-accent-violet-bold-hovered: var(
    --color-base-colors-violet-700
  );
  --color-color-bg-accent-violet-bold-pressed: var(
    --color-base-colors-violet-800
  );
  --color-color-bg-accent-violet-bolder-default: var(
    --color-base-colors-violet-800
  );
  --color-color-bg-accent-violet-bolder-hovered: var(
    --color-base-colors-violet-900
  );
  --color-color-bg-accent-violet-bolder-pressed: var(
    --color-base-colors-violet-1000
  );
  --color-color-bg-accent-teal-lightest-default: var(
    --color-base-colors-teal-50
  );
  --color-color-bg-accent-teal-lightest-hovered: var(
    --color-base-colors-teal-100
  );
  --color-color-bg-accent-teal-lightest-pressed: var(
    --color-base-colors-teal-200
  );
  --color-color-bg-accent-teal-lighter-default: var(
    --color-base-colors-teal-100
  );
  --color-color-bg-accent-teal-lighter-hovered: var(
    --color-base-colors-teal-200
  );
  --color-color-bg-accent-teal-lighter-pressed: var(
    --color-base-colors-teal-300
  );
  --color-color-bg-accent-teal-bolder-default: var(
    --color-base-colors-teal-800
  );
  --color-color-bg-accent-teal-bolder-hovered: var(
    --color-base-colors-teal-900
  );
  --color-color-bg-accent-teal-bolder-pressed: var(
    --color-base-colors-teal-1000
  );
  --color-color-bg-accent-green-light-lightest-default: var(
    --color-base-colors-greenlight-50
  );
  --color-color-border-brand-default: var(--color-base-colors-blue-600);
  --color-color-bg-accent-green-light-lightest-hovered: var(
    --color-base-colors-greenlight-100
  );
  --color-color-bg-accent-green-light-lightest-pressed: var(
    --color-base-colors-greenlight-200
  );
  --color-color-border-brand-bold: var(--color-base-colors-blue-900);
  --color-color-border-brand-light: var(--color-base-colors-blue-300);
  --color-color-bg-accent-green-light-lighter-default: var(
    --color-base-colors-greenlight-100
  );
  --color-color-border-support-danger-default: var(--color-base-colors-red-600);
  --color-color-border-support-danger-light: var(--color-base-colors-red-200);
  --color-color-border-support-warning-default: var(
    --color-base-colors-yellow-600
  );
  --color-color-border-support-warning-light: var(
    --color-base-colors-yellow-200
  );
  --color-color-border-support-success-default: var(
    --color-base-colors-green-600
  );
  --color-color-border-support-success-light: var(
    --color-base-colors-green-200
  );
  --color-color-border-support-info-default: var(
    --color-base-colors-bluelight-600
  );
  --color-color-border-support-info-light: var(
    --color-base-colors-bluelight-200
  );
  --color-color-bg-accent-green-light-lighter-hovered: var(
    --color-base-colors-greenlight-200
  );
  --color-color-border-accent-red-default: var(--color-base-colors-red-600);
  --color-color-border-accent-red-bold: var(--color-base-colors-red-700);
  --color-color-bg-accent-green-light-lighter-pressed: var(
    --color-base-colors-greenlight-300
  );
  --color-color-border-accent-red-light: var(--color-base-colors-red-200);
  --color-color-bg-accent-green-light-bold-default: var(
    --color-base-colors-greenlight-600
  );
  --color-color-border-accent-green-default: var(--color-base-colors-green-600);
  --color-color-border-accent-green-bold: var(--color-base-colors-green-700);
  --color-color-bg-accent-green-light-bold-hovered: var(
    --color-base-colors-greenlight-700
  );
  --color-color-border-accent-green-light: var(--color-base-colors-green-200);
  --color-color-bg-accent-green-light-bold-pressed: var(
    --color-base-colors-greenlight-800
  );
  --color-color-border-accent-orange-default: var(
    --color-base-colors-orange-600
  );
  --color-color-border-accent-orange-bold: var(--color-base-colors-orange-700);
  --color-color-bg-accent-green-light-bolder-default: var(
    --color-base-colors-greenlight-800
  );
  --color-color-border-accent-orange-light: var(--color-base-colors-orange-200);
  --color-color-border-accent-pink-default: var(--color-base-colors-pink-600);
  --color-color-bg-accent-green-light-bolder-hovered: var(
    --color-base-colors-greenlight-900
  );
  --color-color-bg-accent-green-light-bolder-pressed: var(
    --color-base-colors-greenlight-1000
  );
  --color-color-border-accent-pink-bold: var(--color-base-colors-pink-700);
  --color-color-border-accent-pink-light: var(--color-base-colors-pink-200);
  --color-color-border-accent-rose-default: var(--color-base-colors-rose-600);
  --color-color-border-accent-rose-bold: var(--color-base-colors-rose-700);
  --color-color-border-accent-rose-light: var(--color-base-colors-rose-200);
  --color-color-border-accent-yellow-default: var(
    --color-base-colors-yellow-600
  );
  --color-color-border-accent-yellow-bold: var(--color-base-colors-yellow-700);
  --color-color-border-accent-yellow-light: var(--color-base-colors-yellow-200);
  --color-color-border-accent-cyan-default: var(--color-base-colors-cyan-600);
  --color-color-border-accent-cyan-bold: var(--color-base-colors-cyan-700);
  --color-color-border-accent-cyan-light: var(--color-base-colors-cyan-200);
  --color-color-border-accent-violet-default: var(
    --color-base-colors-violet-600
  );
  --color-color-border-accent-violet-bold: var(--color-base-colors-violet-700);
  --color-color-border-accent-violet-light: var(--color-base-colors-violet-200);
  --color-color-border-accent-teal-default: var(--color-base-colors-teal-600);
  --color-color-border-accent-teal-bold: var(--color-base-colors-teal-700);
  --color-color-border-accent-teal-light: var(--color-base-colors-teal-200);
  --color-color-border-accent-green-light-default: var(
    --color-base-colors-greenlight-600
  );
  --color-color-border-accent-green-light-bold: var(
    --color-base-colors-greenlight-700
  );
  --color-color-border-accent-green-light-light: var(
    --color-base-colors-greenlight-200
  );
  --color-color-border-inverse: var(--color-base-colors-white-1000);
  --color-color-border-selected: var(--color-base-colors-blue-200);
  --color-color-bg-neutral-boldest-default: var(--color-base-colors-grey-800);
  --color-color-bg-neutral-boldest-hovered: var(--color-base-colors-grey-900);
  --color-color-bg-neutral-boldest-pressed: var(--color-base-colors-grey-1000);
  --color-brand-100: var(--color-base-colors-blue-100);
  --color-brand-200: var(--color-base-colors-blue-200);
  --color-brand-300: var(--color-base-colors-blue-300);
  --color-brand-400: var(--color-base-colors-blue-400);
  --color-brand-500: var(--color-base-colors-blue-500);
  --color-brand-600: var(--color-base-colors-blue-600);
  --color-brand-700: var(--color-base-colors-blue-700);
  --color-brand-800: var(--color-base-colors-blue-800);
  --color-brand-900: var(--color-base-colors-blue-900);
  --color-brand-1000: var(--color-base-colors-blue-1000);
  --color-color-fg-accent-red-bolder: var(--color-base-colors-red-900);
  --color-color-fg-accent-green-bolder: var(--color-base-colors-green-900);
  --color-color-fg-accent-orange-bolder: var(--color-base-colors-orange-900);
  --color-color-fg-accent-pink-bolder: var(--color-base-colors-pink-900);
  --color-color-fg-accent-rose-bolder: var(--color-base-colors-rose-900);
  --color-color-fg-accent-yellow-bolder: var(--color-base-colors-yellow-900);
  --color-color-fg-accent-violet-default: var(--color-base-colors-violet-600);
  --color-color-fg-accent-violet-bold: var(--color-base-colors-violet-700);
  --color-color-fg-accent-violet-bolder: var(--color-base-colors-violet-900);
  --color-color-fg-accent-teal-bolder: var(--color-base-colors-teal-900);
  --color-color-fg-accent-green-light-bolder: var(
    --color-base-colors-greenlight-900
  );
  --color-color-fg-accent-cyan-bolder: var(--color-base-colors-cyan-900);
  /* default page background */
  --color-color-bg-base-hovered: var(--color-base-colors-grey-50);
  /* default page background */
  --color-color-bg-base-pressed: var(--color-base-colors-grey-100);
  --color-color-bg-accent-blue-light-lightest-default: var(
    --color-base-colors-bluelight-50
  );
  --color-color-bg-accent-blue-light-lightest-hovered: var(
    --color-base-colors-bluelight-100
  );
  --color-color-bg-accent-blue-light-lightest-pressed: var(
    --color-base-colors-bluelight-200
  );
  --color-color-bg-accent-blue-light-lighter-default: var(
    --color-base-colors-bluelight-100
  );
  --color-color-bg-accent-blue-light-lighter-hovered: var(
    --color-base-colors-bluelight-200
  );
  --color-color-bg-accent-blue-light-lighter-pressed: var(
    --color-base-colors-bluelight-300
  );
  --color-color-bg-accent-blue-light-bold-default: var(
    --color-base-colors-bluelight-600
  );
  --color-color-bg-accent-blue-light-bold-hovered: var(
    --color-base-colors-bluelight-700
  );
  --color-color-bg-accent-blue-light-bold-pressed: var(
    --color-base-colors-bluelight-800
  );
  --color-color-bg-accent-blue-light-bolder-default: var(
    --color-base-colors-bluelight-800
  );
  --color-color-bg-accent-blue-light-bolder-hovered: var(
    --color-base-colors-bluelight-900
  );
  --color-color-bg-accent-blue-light-bolder-pressed: var(
    --color-base-colors-bluelight-1000
  );
  --color-color-fg-accent-blue-light-default: var(
    --color-base-colors-bluelight-600
  );
  --color-color-fg-accent-blue-light-bold: var(
    --color-base-colors-bluelight-700
  );
  --color-color-fg-accent-blue-light-bolder: var(
    --color-base-colors-bluelight-900
  );
  --color-brand-50: var(--color-base-colors-blue-50);
  --color-color-bg-neutral-bolder-default: var(--color-base-colors-grey-700);
  --color-color-bg-neutral-bolder-hovered: var(--color-base-colors-grey-800);
  --color-color-bg-neutral-bolder-pressed: var(--color-base-colors-grey-900);
  --color-color-bg-accent-red-bold-default: var(--color-base-colors-red-600);
  --color-color-bg-accent-red-bold-hovered: var(--color-base-colors-red-700);
  --color-color-bg-accent-red-bold-pressed: var(--color-base-colors-red-800);
  --color-color-bg-accent-green-bold-default: var(
    --color-base-colors-green-600
  );
  --color-color-bg-accent-green-bold-hovered: var(
    --color-base-colors-green-700
  );
  --color-color-bg-accent-green-bold-pressed: var(
    --color-base-colors-green-800
  );
  --color-color-bg-accent-orange-bold-default: var(
    --color-base-colors-orange-600
  );
  --color-color-bg-accent-orange-bold-hovered: var(
    --color-base-colors-orange-700
  );
  --color-color-bg-accent-orange-bold-pressed: var(
    --color-base-colors-orange-800
  );
  --color-color-bg-accent-pink-bold-default: var(--color-base-colors-pink-600);
  --color-color-bg-accent-pink-bold-hovered: var(--color-base-colors-pink-700);
  --color-color-bg-accent-pink-bold-pressed: var(--color-base-colors-pink-800);
  --color-color-bg-accent-rose-bold-default: var(--color-base-colors-rose-600);
  --color-color-bg-accent-rose-bold-hovered: var(--color-base-colors-rose-700);
  --color-color-bg-accent-rose-bold-pressed: var(--color-base-colors-rose-800);
  --color-color-bg-accent-yellow-bold-default: var(
    --color-base-colors-yellow-600
  );
  --color-color-bg-accent-yellow-bold-hovered: var(
    --color-base-colors-yellow-700
  );
  --color-color-bg-accent-yellow-bold-pressed: var(
    --color-base-colors-yellow-800
  );
  --color-color-bg-accent-cyan-bold-default: var(--color-base-colors-cyan-600);
  --color-color-bg-accent-cyan-bold-hovered: var(--color-base-colors-cyan-700);
  --color-color-bg-accent-cyan-bold-pressed: var(--color-base-colors-cyan-800);
  --color-color-bg-accent-teal-bold-default: var(--color-base-colors-teal-600);
  --color-color-bg-accent-teal-bold-hovered: var(--color-base-colors-teal-700);
  --color-color-bg-accent-teal-bold-pressed: var(--color-base-colors-teal-800);
  --color-color-border-accent-blue-light-default: var(
    --color-base-colors-bluelight-600
  );
  --color-color-border-accent-blue-light-bold: var(
    --color-base-colors-bluelight-700
  );
  --color-color-border-accent-blue-light-light: var(
    --color-base-colors-bluelight-200
  );
}

:root {
  --border-radius-xl-radius: 15px;
  --border-radius-test-xxl-radius: 25px;
}

:root {
  --measure-dimension-radius-none: 0px;
  --measure-dimension-radius-round: 100000px;
  --measure-dimension-opacity-disabled: 40%;
  --measure-dimension-border-width-xxx-small: 2px;
  --measure-dimension-core-size-0: 0px;
  --measure-dimension-core-size-1: 1px;
  --measure-dimension-core-size-2: 2px;
  --measure-dimension-core-size-4: 4px;
  --measure-dimension-core-size-6: 6px;
  --measure-dimension-core-size-8: 8px;
  --measure-dimension-core-size-10: 10px;
  --measure-dimension-core-size-12: 12px;
  --measure-dimension-core-size-14: 14px;
  --measure-dimension-core-size-16: 16px;
  --measure-dimension-core-size-18: 18px;
  --measure-dimension-core-size-20: 20px;
  --measure-dimension-core-size-22: 22px;
  --measure-dimension-core-size-24: 24px;
  --measure-dimension-core-size-26: 26px;
  --measure-dimension-core-size-28: 28px;
  --measure-dimension-core-size-30: 30px;
  --measure-dimension-core-size-32: 32px;
  --measure-dimension-core-size-36: 36px;
  --measure-dimension-core-size-38: 38px;
  --measure-dimension-core-size-40: 40px;
  --measure-dimension-core-size-42: 42px;
  --measure-dimension-core-size-44: 44px;
  --measure-dimension-core-size-46: 46px;
  --measure-dimension-core-size-48: 48px;
  --measure-dimension-core-size-50: 50px;
  --measure-dimension-core-size-52: 52px;
  --measure-dimension-core-size-54: 54px;
  --measure-dimension-core-size-56: 56px;
  --measure-dimension-core-size-58: 58px;
  --measure-dimension-core-size-60: 60px;
  --measure-dimension-core-size-62: 62px;
  --measure-dimension-core-size-64: 64px;
  --measure-dimension-core-size-68: 68px;
  --measure-dimension-core-size-70: 70px;
  --measure-dimension-core-size-72: 72px;
  --measure-dimension-core-size-74: 74px;
  --measure-dimension-core-size-76: 76px;
  --measure-dimension-core-size-78: 78px;
  --measure-dimension-core-size-80: 80px;
  --measure-dimension-core-size-82: 82px;
  --measure-dimension-space-0: 0px;
  --measure-dimension-opacity-10: 10px;
  --measure-dimension-radius-xxx-small: 2px;
  --measure-dimension-radius-xx-small: 4px;
  --measure-dimension-radius-x-small: 6px;
  --measure-dimension-radius-small: 8px;
  --measure-dimension-radius-medium: 12px;
  --measure-dimension-radius-large: 16px;
  --measure-dimension-radius-x-large: 24px;
  --measure-dimension-radius-xx-large: 32px;
  --measure-dimension-radius-xxx-large: 48px;
  --measure-dimension-space-025: 2px;
  --measure-dimension-space-050: 4px;
  --measure-dimension-space-075: 6px;
  --measure-dimension-space-100: 8px;
  --measure-dimension-space-150: 12px;
  --measure-dimension-space-200: 16px;
  --measure-dimension-space-250: 20px;
  --measure-dimension-space-300: 24px;
  --measure-dimension-space-400: 32px;
  --measure-dimension-space-125: 10px;
  --measure-dimension-space-500: 40px;
  --measure-dimension-space-600: 48px;
  --measure-dimension-space-800: 64px;
  --measure-dimension-space-1000: 80px;
}

/* This file was generated by Supernova and should not be changed manually */
:root {
  --dimension-radius-none: 0px;
  --dimension-radius-round: 100000px;
  --dimension-opacity-disabled: 40px;
  --dimension-border-width-xxx-small: 2px;
  --dimension-core-size-0: 0px;
  --dimension-core-size-1: 1px;
  --dimension-core-size-2: 2px;
  --dimension-core-size-4: 4px;
  --dimension-core-size-6: 6px;
  --dimension-core-size-8: 8px;
  --dimension-core-size-10: 10px;
  --dimension-core-size-12: 12px;
  --dimension-core-size-14: 14px;
  --dimension-core-size-16: 16px;
  --dimension-core-size-18: 18px;
  --dimension-core-size-20: 20px;
  --dimension-core-size-22: 22px;
  --dimension-core-size-24: 24px;
  --dimension-core-size-26: 26px;
  --dimension-core-size-28: 28px;
  --dimension-core-size-30: 30px;
  --dimension-core-size-32: 32px;
  --dimension-core-size-36: 36px;
  --dimension-core-size-38: 38px;
  --dimension-core-size-40: 40px;
  --dimension-core-size-42: 42px;
  --dimension-core-size-44: 44px;
  --dimension-core-size-46: 46px;
  --dimension-core-size-48: 48px;
  --dimension-core-size-50: 50px;
  --dimension-core-size-52: 52px;
  --dimension-core-size-54: 54px;
  --dimension-core-size-56: 56px;
  --dimension-core-size-58: 58px;
  --dimension-core-size-60: 60px;
  --dimension-core-size-62: 62px;
  --dimension-core-size-64: 64px;
  --dimension-core-size-68: 68px;
  --dimension-core-size-70: 70px;
  --dimension-core-size-72: 72px;
  --dimension-core-size-74: 74px;
  --dimension-core-size-76: 76px;
  --dimension-core-size-78: 78px;
  --dimension-core-size-80: 80px;
  --dimension-core-size-82: 82px;
  --dimension-space-0: 0px;
  --dimension-opacity-10: 10px;
  --dimension-radius-xxx-small: var(--dimension-core-size-2);
  --dimension-radius-xx-small: var(--dimension-core-size-4);
  --dimension-radius-x-small: var(--dimension-core-size-6);
  --dimension-radius-small: var(--dimension-core-size-8);
  --dimension-radius-medium: var(--dimension-core-size-12);
  --dimension-radius-large: var(--dimension-core-size-16);
  --dimension-radius-x-large: var(--dimension-core-size-24);
  --dimension-radius-xx-large: var(--dimension-core-size-32);
  --dimension-radius-xxx-large: var(--dimension-core-size-48);
  --dimension-space-025: var(--dimension-core-size-2);
  --dimension-space-050: var(--dimension-core-size-4);
  --dimension-space-075: var(--dimension-core-size-6);
  --dimension-space-100: var(--dimension-core-size-8);
  --dimension-space-150: var(--dimension-core-size-12);
  --dimension-space-200: var(--dimension-core-size-16);
  --dimension-space-250: var(--dimension-core-size-20);
  --dimension-space-300: var(--dimension-core-size-24);
  --dimension-space-400: var(--dimension-core-size-32);
  --dimension-space-125: var(--dimension-core-size-10);
  --dimension-space-500: var(--dimension-core-size-40);
  --dimension-space-600: var(--dimension-core-size-48);
  --dimension-space-800: var(--dimension-core-size-64);
  --dimension-space-1000: var(--dimension-core-size-80);
}

:root {
  --shadow-base-box-shadow-container-l: 0 4px 6px -2px rgba(16, 24, 40, 0.03);

  --shadow-base-box-shadow-container-l-2: 0 12px 16px -4px
    rgba(16, 24, 40, 0.08);

  --shadow-base-box-shadow-focus-border-primary: 0 0 0 4px
    rgba(209, 224, 255, 1);

  --shadow-base-box-shadow-focus-border-gray: 0 0 0 4px rgba(242, 244, 247, 1);

  --shadow-base-box-shadow-focus-border-destructive: 0 0 0 4px
    rgba(254, 228, 226, 1);

  --shadow-base-box-shadow-container-xs: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  --shadow-base-box-shadow-container-none: 0 0 0 0 rgb(0, 0, 0);

  --shadow-base-box-shadow-container-m: 0 2px 4px -2px rgba(16, 24, 40, 0.06);

  --shadow-base-box-shadow-container-m-2: 0 4px 8px -2px rgba(16, 24, 40, 0.1);

  --shadow-base-box-shadow-container-xl: 0 8px 8px -4px rgba(16, 24, 40, 0.03);

  --shadow-base-box-shadow-container-xl-2: 0 20px 24px -4px
    rgba(16, 24, 40, 0.08);

  --shadow-base-box-shadow-container-sm: 0 1px 2px 0 rgba(16, 24, 40, 0.06);

  --shadow-base-box-shadow-container-sm-2: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
}
